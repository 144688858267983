import { withInstall } from 'framework/utils'
import appSiteSelect from './src/AppSiteSelect.vue'
import appCurrencySelect from './src/AppCurrencySelect.vue'
import appSiteTabs from './src/AppSiteTabs.vue'
import dataSelect from './src/DataSelect.vue'

import { useComponentRegister } from 'framework/components/Form/index'
import appCategory from '../Category/Category.vue'

// 将admin的component注入到 framenwork的Form组件中
useComponentRegister('AppCategory', appCategory)
useComponentRegister('AppSiteSelect', appSiteSelect)
useComponentRegister('DataSelect', dataSelect)
useComponentRegister('AppCurrencySelect', appCurrencySelect)

export const AppCategory = withInstall(appCategory)
export const AppSiteSelect = withInstall(appSiteSelect)
export const AppCurrencySelect = withInstall(appCurrencySelect)
export const AppSiteTabs = withInstall(appSiteTabs)
export const DataSelect = withInstall(dataSelect)
