import { queryEnumUsers } from '@admin/api/system/user'

const originOptions = ref<any>([])
const loading = ref<boolean>(false)

export default function useDataSelect(): any {
  const getOptions = async () => {
    if (!originOptions.value.length && !loading.value) {
      try {
        loading.value = true
        const { data } = await queryEnumUsers()
        originOptions.value = data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            key: item.id,
          }
        })
      } catch (error) {
        console.log('getEnumUsersError:', error)
      } finally {
        loading.value = false
      }
    }
  }
  onMounted(() => {
    getOptions()
  })

  return {
    originOptions,
  }
}
